import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

function Contact() {
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState(false);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    details: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number required"),
    details: Yup.string().required("Details is required"),
  });

  const inputHandler = (event, editor) => {
    formik.setFieldValue("details", editor.getData());
  };

  const onSubmit = async (
    values,
    { setSubmitting, setErrors, setStatus, resetForm }
  ) => {
    console.log("values", values);

    //save your form data
    //await signatoryInfoCreateAction(fd)
  };

  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  return (
    <section className="relative w-full h-full bg-primary py-20">
      <div className=" mx-auto  h-full  flex items-center justify-center text-center flex-col">
        <div
          data-aos="fade-in"
          data-aos-once="true"
          className="lg:text-3xl text-2xl space-y-5"
        >
          <h1 className="text-3xl md:text-5xl font-bold text-black-100">
            Contact Us
          </h1>
          <p className="text-black-400 text-sm">We are here to help</p>
        </div>
        {isSubmit && (
          <div
            class="bg-gradient-to-r from-primary to-secondary  animate-fade-in-down rounded-lg my-10 py-5 px-6 mb-3 text-base text-green-700 inline-flex items-center w-full"
            role="alert"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="check-circle"
              class="w-4 h-4 mr-2 fill-current"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
              ></path>
            </svg>
          </div>
        )}
        {!isSubmit && (
          <>
            <div
              data-aos="fade-up"
              data-aos-once="true"
              className="lg:bg-lightblack max-w-7xl lg:mt-10 mt-2 w-full p-10 rounded-lg lg:drop-shadow-xl"
            >
              <FormikProvider value={formik}>
                <div>
                  <Form className="text-black">
                    <div className="flex text-sm  lg:text-lg items-start justify-start mb-5 text-left w-full flex-col">
                      <div className="grid grid-cols-1 gap-y-3 w-full">
                        <label htmlFor="name"> Your Name </label>
                        <Field
                          required
                          name="name"
                          type="text"
                          className="form-control text-black focus:border-secondary py-2 px-4 focus:ring-lime  border-borderblack lg:border-white shadow-sm"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-red text-sm"
                        />
                      </div>
                    </div>
                    <div className="grid text-sm lg:text-lg items-start justify-start mb-5 text-left  grid-cols-1 md:grid-col-2 gap-5">
                      <div className="grid grid-cols-1 gap-y-3 w-full">
                        <label htmlFor="email"> Email </label>
                        <Field
                          required
                          name="email"
                          type="text"
                          className="form-control text-black focus:border-secondary py-2 px-4 focus:ring-lime  border-borderblack lg:border-white shadow-sm"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red text-sm"
                        />
                      </div>
                      <div className="grid grid-cols-1 gap-y-3 w-full">
                        <label htmlFor="phone"> Phone </label>
                        <Field
                          required
                          name="phone"
                          type="text"
                          className="form-control text-black focus:border-secondary py-2 px-4 focus:ring-lime  border-borderblack lg:border-white shadow-sm"
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="text-red text-sm"
                        />
                      </div>
                    </div>

                    <div className="col-span-4  flex items-start justify-start text-left w-full h-full ">
                      <div className="flex flex-col justify-start gap-y-3 w-full h-full ">
                        <label className="col-sm-2">Details</label>
                        <textarea
                          required
                          name="details"
                          className="form-control"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="details"
                          component="div"
                          className="text-red text-sm"
                        />
                      </div>
                    </div>

                    <div className="lg:mt-10 mt-5">
                      <button
                        type="submit"
                        className="px-8 p-2 bg-black text-white text-sm lg:text-base font-medium hover:shadow-lg hover:brightness-105 "
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                </div>
              </FormikProvider>
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default Contact;
