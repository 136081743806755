import React from "react";
import Gallery from "./pages/home/gallery/Gallery";
import PhotoBanner from "./pages/home/photobanner/PhotoBanner";
import RootLayout from "./layout/RootLayout";
import Services from "./pages/home/services/Services";
import Packages from "./pages/packges/Packages";
import Contact from "./pages/contact/Contact";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaWhatsapp, FaYoutube } from "react-icons/fa";

export default function App() {
  let myRef = React.useRef(null);

  return (
    <RootLayout
      
    >
      <div className="w-screen overflow-hidden">
      <div ref={myRef}>
        <PhotoBanner />
      </div>

      <Services />
      <Packages />

      <Contact />
      <a
        href="https://wa.me/message/VKJEY3M2CY7XL1"
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-5 space-x-3 px-4 rounded-full p-3 bg-white shadow border border-neutral-200 right-10  flex justify-center items-center"
      >
        <FaWhatsapp className="w-10 h-10 text-green-500" />
      </a>
      {/* instagram, facebook, x */}
      <div className="z-50 space-x-10 px-4 rounded-full p-3 bg-white shadow border border-neutral-200 right-20  flex justify-center items-center">
        <Link to="/">
          <FaFacebook className="w-10 h-10 text-slate-500" />
        </Link>
        <Link to="/">
          <FaYoutube className="w-10 h-10 text-slate-500" />
        </Link>
        <Link to="/">
          <FaInstagram className="w-10 h-10 text-slate-500" />
        </Link>
      </div>
      </div>
    </RootLayout>
  );
}
