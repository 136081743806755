import React from "react";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import Logo from "../../../assets/logo/logo-white.svg";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MobileNavBar() {
  return (
    <div className="fixed top-0 z-50">
      <div className="bg-primary/90 w-screen flex flex-row justify-between items-center p-5">
        <h1 className="text-xl  font-light text-gray-50">
          <img src={Logo} className="w-12" alt="logo" />
        </h1>
        <Popover.Group className=" ml-2  rounded-md p-2 lg:hidden inline-flex items-center justify-center  focus:outline-none">
          <Popover>
            {({ open }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open ? "text-white" : "text-white",
                    "group  rounded-md inline-flex items-center text-base  font-medium hover:text-white focus:outline-none  focus:ring-secondary"
                  )}
                >
                  {open && (
                    <>
                      <XIcon
                        className={classNames(
                          open ? "text-white/20" : "text-black",
                          "h-8 w-8 mt-1 group-hover:text-black"
                        )}
                        aria-hidden="true"
                      />
                    </>
                  )}

                  {!open && (
                    <>
                      <MenuIcon
                        className={classNames(
                          open ? "text-white/20" : "text-black",
                          "h-8 w-8 mt-1 group-hover:text-white"
                        )}
                        aria-hidden="true"
                      />
                    </>
                  )}
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-x-full"
                  enterTo="opacity-100 translate-x-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-x-0"
                  leaveTo="opacity-0 translate-x-full"
                >
                  <Popover.Panel className="absolute backdrop-blur-8xl h-[94vh] z-10 top-full inset-x-0 transform shadow-lg bg-primary/90">
                    <div className="p-10 flex flex-col justify-between h-[8vh]  gap-y-10">
                      <ul className="flex flex-col items-start  justify-start gap-y-5 xl:gap-x-20 text-base uppercase text-white/80 font-semibold">
                        <Link to="?id:1" className="link hover:brightness-125 ">
                          Home
                        </Link>

                        <Link to="?id:2" className="link hover:brightness-125 ">
                          Services
                        </Link>

                        <Link
                          to="/winners"
                          className="link hover:brightness-125 "
                        >
                          Gallery
                        </Link>
                        <Link
                          to="/gallery"
                          className="link hover:brightness-125 "
                        >
                          Packages
                        </Link>
                        <Link
                          to="/downloads"
                          className="link hover:brightness-125 "
                        >
                          Contact
                        </Link>
                      </ul>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </Popover.Group>
      </div>
    </div>
  );
}
