import React from "react";
import Footer from "../components/footer/Footer";
import NavBar from "../components/navigations/nav-bar/Navigation";
import MobileNavBar from "../components/navigations/mobile-navbar/MobileNavBar";

export default function RootLayout({ children }) {
  return (
    <div className="relative w-screen">
      <div className="hidden md:flex">
        <NavBar />
      </div>
      <div className="md:hidden">
        <MobileNavBar />
      </div>
      <div className="bg-white w-screen  flex flex-col justify-center items-center">
        {children}
      </div>
      {/* <Footer /> */}
    </div>
  );
}
