import React from "react";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useRouteMatch,
} from "react-router-dom";
import App from "../App";
import Home from "../pages/home/Home";

export default function PageRoutes() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<App />}>
          <Route exact path="home" element={<Home />} />
        </Route>
      </Routes>
    </Router>
  );
}
