import React from "react";
import { motion } from "framer-motion";

export default function PhotoBanner() {
  let images = [
    {
      url: "https://cdn0.weddingwire.com/article/0692/3_2/960/jpg/22960-wedding-party-20-mile-house-peyton-rainey-photography.jpeg",
    },
    {
      url: "https://images.squarespace-cdn.com/content/v1/52540926e4b0d49865bee20d/1529399343019-AXOYM1ALU34YUN2LMUJ7/1st+birthday+party+photography+london?format=1500w",
    },
    {
      url: "https://images.squarespace-cdn.com/content/v1/540eb65ae4b0e71908adf398/1574890110896-4QJZBRPVRPAI0WH26G7J/DSC0007.jpg",
    },
  ];

  return (
    <div className=" w-screen h-screen relative    flex flex-col md:flex-row items-start justify-start  ">
      <div className=" w-full bg-gradient-to-b bg-primary h-screen md:h-screen   flex flex-col md:flex-row  items-center justify-center  ">
        <div className="max-w-7xl w-full  flex flex-col md:flex-row  items-center justify-between p-10 ">
          <motion.div
            initial={{ opacity: 0, translateY: 30 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            viewport={{ once: true }}
            className="py-10 flex space-y-5 flex-col"
          >
            <h5 className="text-black-500  uppercase text ">Your Dream day</h5>
            <h1 className="text-5xl md:text-7xl wedding-font font-medium text-black-100 ">
              Celebrate your special day with us
            </h1>
          </motion.div>
          <div className="flex pb-10 justify-start items-start md:justify-end md:items-end text-start md:text-right flex-col text-md space-y-5 text-gray-400">
            <p className="w-full text-black md:w-1/2">
              A profession to provde best in class event decoration services to
              ensure the the implementation of the events run perfectly
            </p>
            <button className="p-3 px-6 hover:brightness-110 text-xs w-fit bg-black text-white ">
              Enquire with us today
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
