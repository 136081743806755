import React from "react";
import Logo from "../../../assets/logo/logo-white.svg";

export default function NavBar() {
  let [bgColor, setBgColor] = React.useState("transparent");
  // scroll listener
  let [scrollY, setScrollY] = React.useState(0);

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollY(window.scrollY);
    });
    if (window.scrollY > 0) {
      // gradient
      setBgColor("bg-blur-xl bg-white ");
    } else {
      setBgColor("bg-white");
    }
  }, [scrollY]);

  return (
    <div
      className={`${bgColor} hidden md:flex mx-auto fixed z-50 w-full p-5 justify-around flex-row items-center`}
    >
      <h1 className="text-xl  font-light text-gray-50">
        <img src={Logo} className="w-12" alt="logo" />
      </h1>
      <div className="flex flex-row space-x-10">
        {["Home", "Services", "Packages", "Gallery", "Contact"].map(
          (item, i) => (
            <h1
              key={i}
              className="text-gray-900 text-sm uppercase font-bold  hover:text-gray-100 cursor-pointer"
            >
              {item}
            </h1>
          )
        )}
      </div>
      <button className="p-3 px-6 hover:brightness-110 text-xs w-fit bg-white text-black ">
        Enquire with us today
      </button>
    </div>
  );
}
