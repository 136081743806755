import { motion, Variants } from "framer-motion";
import { Link } from "react-router-dom";

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  slideStart: { clipPath: "inset(0 100% 0 0 )" },
  slideEnd: { clipPath: "inset(0 0% 0 0 )" },
};

const services = [
  {
    name: "Birthday Party",
    image:
      "https://images.squarespace-cdn.com/content/v1/52540926e4b0d49865bee20d/1529399343019-AXOYM1ALU34YUN2LMUJ7/1st+birthday+party+photography+london?format=1500w",
    description:
      "Celebrate your birthday with us. We offer the best services for your birthday party",
    route: null,
  },
  {
    name: "Gender Reveal Party",
    image:
      "https://images.squarespace-cdn.com/content/v1/540eb65ae4b0e71908adf398/1574890110896-4QJZBRPVRPAI0WH26G7J/DSC0007.jpg",
    description:
      "Let us help you reveal the gender of your baby in a grand style",
    route: null,
  },
  {
    name: "Corporate Events",
    image:
      "https://orlandosydney.com/wp-content/uploads/2019/12/Event-Photography-by-orlandosydney.com-OS2_5654.jpg",
    description:
      "Wedding variants is assigned a value but never used  no-unused-vars variants is assigned a value but never used  no-unused-vars ",
    route: null,
  },
];

export default function Services() {
  return (
  <div
    className="flex w-full justify-center items-center bg-white"
  >
      <div className="flex w-full max-w-6xl flex-col space-y-1 py-20">
      <div className="flex flex-col space-y-2 p-10 text-center items-start justify-start">
        <h1 className="text-3xl md:text-5xl font-bold text-gray-700">
          Our Services
        </h1>
        <p className="text-gray-400 text-sm">
          From wedding to birthday parties, we do it all
        </p>
      </div>

      <div className=" grid grid-cols-1 md:grid-cols-2 gap-5  mx-5">
        {services?.map((service, i) => (
          <div className="relative bg-neutral-100">
            <div className="p-10 flex flex-col space-y-3 ">
              <h1 className="text-xl md:text-3xl font-bold">{service.name}</h1>
              <h1 className="text-sm text-gray-400">{service.description}</h1>
         
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
  
  );
}
