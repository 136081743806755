import { Tab } from "@headlessui/react";
import React from "react";
import { faker } from "@faker-js/faker";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

let tabs = [

  {
    name: "Birthday Parties and Gender Reveal",
    panel: {
      title: "text",
      content: "text",
      packages: [
        {
          name: "Gold",
          color: "yellow",
          image:
            "https://blanc-events-bucket.s3.ap-south-1.amazonaws.com/IMG_2251.JPG",

          description: "text",
          price: "3,000",
          features: [],
        },
        {
          name: "Emerald",
          color: "emerald",
          image:
            "https://blanc-events-bucket.s3.ap-south-1.amazonaws.com/IMG_9159.JPG",
          description: "text",
          price: "5,000",
          features: [],
        },
        {
          name: "Diamond",
          description: "text",
          image:
            "https://blanc-events-bucket.s3.ap-south-1.amazonaws.com/IMG_2325.JPEG",
          color: "neutral",
          price: "8,000",
          features: [],
        },
        {
          name: "Black Diamond",
          color: "black",
          image:
            "https://blanc-events-bucket.s3.ap-south-1.amazonaws.com/IMG_1617.JPG",
          description: "text",
          price: "10,000",
          features: [],
        },
      ],
    },
  },
  {
    name: "Engagement Parties",
    panel: {
      title: "text",
      content: "text",
      packages: [
        {
          name: "Custom",
          description: "text",
          price: "Inquire",
          features: [],
        },
      ],
    },
  },
];

export default function Packages() {
  const [selectedTab, setSelectedTab] = React.useState("Wedding Parties");
  return (
    <div className="w-full bg-neutral-100 px-5 py-20 flex justify-center items-center p-10">
      <div className="w-full px-5  max-w-7xl space-y-10">
        <div className="flex flex-col w-full justify-start items-start space-y-5">
          <h1 className="text-3xl md:text-5xl font-semibold text-center text-neutral-800">
            Our Packages
          </h1>
          <p className="text-sm text-center text-neutral-700">
            From wedding to birthday parties, we do it all
          </p>
        </div>
        <Tab.Group>
          <div className="flex flex-col space-y-5">
            <Tab.List
              className={
                "flex flex-row w-[calc(100vw-20px)] overflow-auto space-x-5 pr-5"
              }
            >
              {tabs.map((tabItem, i) => (
                <Tab
                  //   onClick={() => {
                  //     navigate(`${url}?tab=${tabItem.path}`);
                  //   }}
                  onClick={() => {
                    setSelectedTab(tabItem.name);
                  }}
                  className={({ selected }) =>
                    classNames(
                      " text-center capitalize px-4 w-max  min-w-[200px] focus:outline-none py-2   text-sm md:text-md  hover:cursor-pointer  ",
                      selected
                        ? " bg-neutral-800  text-white   font-semibold "
                        : "text-neutral-700 hover:bg-neutral-100/80 border-transparent "
                    )
                  }
                >
                  {tabItem.name}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="w-ful ">
              {tabs.map((tabItem, i) => (
                <Tab.Panel>
                  {tabItem.name === selectedTab && (
                    <div className=" grid grid-cols-1 md:grid-cols-4 gap-5">
                      {tabItem.panel.packages.map((packageItem, i) => (
                        <div
                          className={`flex  min-w-4xl  bg-white flex-col space-y-5`}
                        >
                          <img
                            src={
                              packageItem?.image ??
                              "https://blanc-events-bucket.s3.ap-south-1.amazonaws.com/Instagram+post+-+4.png"
                            }
                            className={`h-96 w-full object-cover object-top bg-gradient-to-br from-${packageItem?.color}-200 to-${packageItem?.color}-900`}
                          />

                          <div className="flex flex-col space-y-2 px-5 pb-5">
                            <h1 className="text-xl md:text-2xl font-semibold text-neutral-800">
                              {packageItem.name}
                            </h1>

                            <div className="flex flex-col space-y-1">
                              {packageItem?.features?.map((feature, i) => (
                                <div className="flex flex-row space-x-2 items-center">
                                  <div className="w-1 h-1 bg-neutral-800 rounded-full"></div>
                                  <p className="text-xs text-neutral-700">
                                    {feature}
                                  </p>
                                </div>
                              ))}
                            </div>
                            {/* price */}
                            <div className="flex flex-row justify-between items-center">
                              <p className="text-xs md:text-sm text-neutral-700">
                                Starting from
                              </p>
                              <p className="text-xl md:text-2xl font-semibold text-black">
                                {packageItem.price}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </div>
  );
}
